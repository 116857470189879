@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body{
  background-color: #171717 !important;
  font-family: 'Poppins', sans-serif;
  color: #EFEFEF !important;
  margin: 0;
  padding: 0;
  height: 100vh;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
}

p{
  margin: 0;
}

*,
::before,
::after {
  box-sizing: border-box;
}

#PDFOBJECTREPORT{
  height: 100vh;
}

/* CHECKBOX STYLES TEMPORAL */
.round {
  position: relative;
}

.round label {
  background-color: transparent;
  border: 1px solid #FF6F00;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #FF6F00 !important;
  border-color: #FF6F00 !important;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

/* GESTION */
.contentGestion{
    display: grid;
    padding: 0 0.5em;
    grid-gap: 10px;
    align-items: center;
}
@media screen and (min-width: 1920px) {
  .contentGestion{
    align-items: flex-start;
    grid-template-columns: 60% 40%;
  }
}

.contentGestion div:nth-child(2n){
}
@media only screen and (min-width: 1920px) {
  .contentGestion div:nth-child(2n) {
    padding-left: 1em;
  }
}

.accordion .card {
  background-color: #232323;
  border-radius: 1em;
  margin-bottom: 1em;
  border-color: transparent;

}
.accordion .card-header {
    display: grid;
    grid-template-columns: 8% 92%;
    border-bottom: none;

}
.accordion .card-header button {
      width: 100%;
      color: #EFEFEF;
      background-color: transparent;
      border: none;
      text-align: left;
      font-size: 1.2em;
      font-family: 'Poppins';
      font-weight: 600;
}

/* MODAL FULLSCREEN */
.modal-fullscreen .modal-content{
  color: #EFEFEF;
  background-color: #232323;
}

.modal-fullscreen .modal-header {
  border-bottom: 1px solid #FF6F00;
}

.btn-close{
  filter: invert(1);
}

/* LOGIN */
.signin{
  max-width: 300px;
  margin: 0 auto;
}

.signin > form{
  display: grid;
  flex-direction: column;
}
.signin > form > input{
  margin-bottom: 10px;
}

/* SWAL MODAL */
.swal-modal {
  background-color: #232323;
  /* border: 3px solid #FF6F00; */
}

.swal-title {
  color: #EFEFEF;
}

.swal-text {
  color: #a3a3a3;
}


.swal-button--confirm-watsy{
  background-color: #FF6F00;
  color: #EFEFEF;

  }
.swal-button--confirm-watsy:hover{
  background-color: #ec6701 !important;
}
.swal-icon--success:after,
.swal-icon--success::before{
  background-color: transparent;
}



.swal-icon--success__hide-corners{
  background-color: transparent;
}